import { ProductCategories } from '../../types';

export function SetQueryKey(productCategory: ProductCategories) {
    let key: keyof typeof names = productCategory as ProductCategories;
    return key;
}
const qualities = ['kuiva', 'tuore'];
const deliveryTypes = [
    'irto',
    'pakattu, motti tai enemmän',
    'pakattu alle motti',
    'pakattu',
];
const productCategories = ['Polttopuut', 'Muut_tuotteet', 'Palvelut'];
const plengths = [
    'Pilke 25cm',
    'Pilke 30cm',
    'Pilke 33cm',
    'Pilke 40cm',
    'Pilke 50cm',
    'Halko 1m',
    'Sahapinta 3-5m',
    'Polttoranka 3-5m',
    'Muu pituus',
];
const materials = [
    'koivu',
    'kuusi',
    'mänty',
    'haapa',
    'leppä',
    'sekapuu',
    'lehtisekapuu',
    'havusekapuu',
];

const names = {
    Polttopuut: ['Halko', 'Polttopuu'],
    Muut_tuotteet: [
        'Briketti',
        'Grillihiilet',
        'Hake',
        'Halkoliiterit',
        'Joulukuuset',
        'Klapikoneet',
        'Kuorikate',
        'Kuorike',
        'Moottorisahat',
        'Moottorisahaveistokset',
        'Muut metsätyötarvikkeet',
        'Muu tuote',
        'Polttopuun pakkaustarvikkeet',
        'Polttopuusäkki 40-50 litr',
        'Polttopuutarvikkeet',
        'Puupelletti',
        'Sahatavara',
        'Sytytyspalat',
    ],
    Palvelut: [
        'Haketus',
        'Halkojen pinonta',
        'Kaadonsuuntaajan vuokraus',
        'Kantojyrsintä',
        'Kuljetukset',
        'Metsuripalvelut',
        'Metsänhoito',
        'Pihapuiden kaato',
        'Pilkekoneen vuokraus',
        'Pilkkeen teko',
        'Polttopuun pinoaminen',
        'Rahtisahaus',
        'Rajojen merkintä ja aukaisu',
    ],
};

const units = [
    'kpl',
    'tunti',
    'vuorokausi',
    'kuutio',
    'Irto-m3',
    'Pino-m3',
    'Kiinto-m3',
];

const vatValues = [2550, 1000];

export const ProductFormOptionLists = {
    qualities,
    deliveryTypes,
    names,
    plengths,
    materials,
    productCategories,
    units,
    vatValues,
};

const deliveryMethods = ['Noutopiste', 'Kuljetus', 'Noutopiste ja kuljetus'];

export const AccountSettingsOptionLists = {
    deliveryMethods,
};

export const productSearchFormOptionLists = {
    productCategories,
    deliveryMethods,
    names,
};

const subjects = ['palaute', 'kehitysidea', 'yhteydenottopyyntö'];
const statuses = ['recieved', 'in progress', 'resolved'];

export const contactFormOptionLists = {
    subjects,
    statuses,
};

/** Admin panel option lists */

export interface AccountState {
    text: 'kyllä' | 'ei';
    state: boolean;
}

const accountStates: AccountState[] = [
    { text: 'kyllä', state: true },
    { text: 'ei', state: false },
];

export const adminPanelOptionLists = {
    accountStates,
};

/** DeliveryCosts option List */
const DeliveryCostNames = ['Kilometrien mukaan', 'Kiinteä hinta'];
const DeliveryCostUnits = ['kpl', 'km'];
export const deliveryCostsOptionLists = {
    DeliveryCostNames,
    DeliveryCostUnits,
};
