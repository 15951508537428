import React, { FC } from 'react';
import './Order.scss';
import {
    Typography,
    Box,
    Grid,
    Paper,
    TableCell,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableFooter,
    Button,
} from '@material-ui/core';
import { resolveStatus, setPriceTag } from '../../utils/utils';
import { ArrowLeft, Print } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import {
    IOrderItem,
    IOrderTotals,
    OrderStatus,
    IUser,
    IOrder,
} from '../../../../types';

interface IOrderStateProps {
    order: IOrder;
    orderTotals: IOrderTotals;
    user?: IUser;
    returnOrders?: () => void;
    updateOrderStatus?: (id: string, status: OrderStatus) => void;
}

type OrderProps = IOrderStateProps;

const Order: FC<OrderProps> = ({
    order,
    orderTotals,
    returnOrders,
    updateOrderStatus,
}) => {
    const print = () => {
        window.print();
    };
    return (
        <Box m={4}>
            <Grid container={true}>
                <Grid item={true} xs={12}>
                    <Paper className="a4Doc">
                        <Box p={4}>
                            <Table
                                style={{
                                    tableLayout: 'fixed',
                                }}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <Typography variant="h6">
                                                Halkoliiteri.com
                                            </Typography>
                                            <hr />
                                            <Typography variant="body1">
                                                Tilaaja:
                                            </Typography>
                                            <Typography variant="body1">
                                                Nimi:{' '}
                                                {order.buyer.firstname +
                                                    ' ' +
                                                    order.buyer.lastname}
                                            </Typography>
                                            <Typography variant="body1">
                                                Puhelinnumero:{' '}
                                                {order.buyer.phonenumber}
                                            </Typography>
                                            <Typography variant="body1">
                                                Email: {order.buyer.email}
                                            </Typography>
                                            <Typography variant="body1">
                                                Osoite:{' '}
                                                {order.buyer.address.street}
                                            </Typography>
                                            <Typography variant="body1">
                                                {order.buyer.address.zip +
                                                    ' ' +
                                                    order.buyer.address.locality
                                                        .location}
                                            </Typography>
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            <Typography variant="h6">
                                                Tilausvahvistus
                                            </Typography>
                                        </TableCell>
                                        <TableCell colSpan={2}>
                                            <Typography variant="h6">
                                                nro. {order.orderNumber}
                                            </Typography>
                                            <Typography variant="body1">
                                                Tilauksen tila:{' '}
                                                {resolveStatus(order.status)}
                                            </Typography>
                                            {order.createdAt && (
                                                <Typography variant="body1">
                                                    Tilaus luotu:{' '}
                                                    {new Date(
                                                        order.createdAt,
                                                    ).toLocaleDateString('fi')}
                                                </Typography>
                                            )}
                                            {order.seller && (
                                                <>
                                                    <Typography variant="body1">
                                                        Myyjä:{' '}
                                                        {order.seller
                                                            .companyName
                                                            ? order.seller
                                                                  .companyName
                                                            : order.seller
                                                                  .fullName}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        {
                                                            order.seller.address
                                                                .street
                                                        }
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        {order.seller.address
                                                            .zip +
                                                            ' ' +
                                                            order.seller.address
                                                                .locality
                                                                .location}
                                                    </Typography>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell variant="head">
                                            Tuotekategoria
                                        </TableCell>
                                        <TableCell variant="head">
                                            Materiaali
                                        </TableCell>
                                        <TableCell variant="head">
                                            Pituus
                                        </TableCell>
                                        <TableCell variant="head">
                                            Laatu
                                        </TableCell>
                                        <TableCell variant="head">
                                            Määrä
                                        </TableCell>
                                        <TableCell variant="head">
                                            Hinta
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {order.items.map((item: IOrderItem) => {
                                        return (
                                            <TableRow key={item._id}>
                                                <TableCell>
                                                    {item.name}
                                                </TableCell>
                                                <TableCell>
                                                    {item.material}
                                                </TableCell>
                                                <TableCell>
                                                    {item.plength}
                                                </TableCell>
                                                <TableCell>
                                                    {item.quality}
                                                </TableCell>
                                                <TableCell>
                                                    {item.quantity +
                                                        ' ' +
                                                        item.unit}
                                                </TableCell>
                                                <TableCell>
                                                    {setPriceTag(
                                                        item.unitPrice *
                                                            item.quantity,
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {order.deliveryCost && (
                                        <TableRow>
                                            <TableCell>
                                                {order.deliveryCost?.name}
                                            </TableCell>
                                            <TableCell>Toimituskulu</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>1</TableCell>
                                            <TableCell>
                                                {order.deliveryCost?.unit}
                                            </TableCell>
                                            <TableCell>
                                                {setPriceTag(
                                                    order.deliveryCost
                                                        ?.price as number,
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow>
                                        <TableCell colSpan={4} />
                                        <TableCell>Veroton hinta:</TableCell>
                                        <TableCell>
                                            {setPriceTag(
                                                orderTotals.totalPriceExcludingTax,
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={4} />
                                        <TableCell>Alv 25,5%:</TableCell>
                                        <TableCell>
                                            {setPriceTag(
                                                orderTotals.totalTaxes,
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={4} />
                                        <TableCell>Kokonais hinta:</TableCell>
                                        <TableCell>
                                            {setPriceTag(
                                                orderTotals.totalPrice,
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <Typography variant="body1">
                                                <strong>Tilausehdot</strong>
                                            </Typography>
                                            <Typography variant="body1">
                                                Tilaus on Halkoliiterin{' '}
                                                <Link
                                                    style={{ color: 'black' }}
                                                    to="/myyntiehdot"
                                                >
                                                    <strong>
                                                        myyntiehtojen
                                                    </strong>
                                                </Link>{' '}
                                                mukaisesti sitova.
                                            </Typography>
                                        </TableCell>
                                        <TableCell />
                                        <TableCell colSpan={2}>
                                            <Typography variant="body1">
                                                <strong>Toimitusehdot</strong>
                                            </Typography>
                                            <Typography variant="body1">
                                                Kotiinkuljetus ja toimitusajat
                                                sekä mahdolliset muut kauppaan
                                                liittyvät palvelut sovitaan aina
                                                kaupan osapuolten kesken
                                                erikseen.
                                            </Typography>
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                    <TableRow>
                                        {returnOrders ? (
                                            <TableCell>
                                                <Button
                                                    startIcon={<ArrowLeft />}
                                                    variant="outlined"
                                                    color="secondary"
                                                    aria-label="back to all orders"
                                                    onClick={returnOrders}
                                                >
                                                    Takaisin
                                                </Button>
                                            </TableCell>
                                        ) : (
                                            <TableCell />
                                        )}
                                        <TableCell />
                                        {updateOrderStatus ? (
                                            <TableCell>
                                                <Button
                                                    variant="outlined"
                                                    color="default"
                                                    aria-label="update order status"
                                                    onClick={() =>
                                                        updateOrderStatus(
                                                            order._id,
                                                            order.status ===
                                                                'recieved'
                                                                ? 'in progress'
                                                                : order.status ===
                                                                  'in progress'
                                                                ? 'in delivery'
                                                                : 'delivered',
                                                        )
                                                    }
                                                >
                                                    {order.status === 'recieved'
                                                        ? 'Käsittelyssä'
                                                        : order.status ===
                                                          'in progress'
                                                        ? 'Tomituksessa'
                                                        : 'Toimitettu'}
                                                </Button>
                                            </TableCell>
                                        ) : (
                                            <TableCell />
                                        )}
                                        <TableCell />
                                        <TableCell />
                                        <TableCell>
                                            <Button
                                                startIcon={<Print />}
                                                variant="outlined"
                                                color="primary"
                                                aria-label="print screen"
                                                onClick={print}
                                            >
                                                Tulosta
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Order;
